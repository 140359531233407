import { useTranslation } from 'next-i18next'
import { FC, useEffect } from 'react'

import styles from './PartnerProgram.module.scss'
import { useUi } from '@/bus/ui'
import { ContrastBgSide } from '@/layouts'
import {
  AboutPartnerProgram,
  DependenceAwards,
  ParticipantPartnerProgram,
  PartnerAwards,
  Plots,
  Program,
  Promocodes,
  RewardCalculator,
  Security
} from '@/sections'


export const PartnerProgram: FC = () => {
  const { t } = useTranslation('remuneration')

  const { setSettings } = useUi()

  useEffect(() => {
    setSettings({ menu: true, footer: true })
    // eslint-disable-next-line
  }, [])

  return (
    <>
      <div className={styles['partner-program']}>
        <AboutPartnerProgram className={styles['partner-program__about']} />
        <ContrastBgSide>
          <RewardCalculator
            className={styles['partner-program__reward-calculator']}
          />
          <Program
            className={styles['partner-program__program']}
            heading={t('heading')}
            summary={t('summary')}
            table={t('table', { returnObjects: true })}
            notice={t('notice')}
          />
        </ContrastBgSide>
        <DependenceAwards
          className={styles['partner-program__dependence-awards']}
        />
        <Plots className={styles['partner-program__plots']} />
        <Promocodes className={styles['partner-program__promocodes']} />
        <ParticipantPartnerProgram
          className={styles['partner-program__participant']}
        />
        <PartnerAwards className={styles['partner-program__awards']} />
        <Security className={styles['partner-program__security']} />
      </div>
    </>
  )
}
